.fraction-slider{
	position:relative;
	width:100%; height:100%;
	overflow:visible;
}

.fraction-slider .slide{
	display:none; width:100%; height:100%;
	position:absolute;
	z-index:5000;
}

.fraction-slider .active-slide{
	z-index:9999;
}

.fraction-slider .fs_obj{
	display:block; display:none;
	position:absolute;
	top:0px; left:100%;
	z-index:7000;
}

.fraction-slider .fs_fixed_obj{
	z-index:6000;
	left:0;
}

.fraction-slider .fs_obj *{
	display:inline-block;
	position:relative;
	top:0px; left:0px;
}

.fs_loader{
	width:100%; height:400px;
	background: url(../img/basic/loader.gif) center center no-repeat transparent;
}

/** CONTROLS **/

.fraction-slider .prev,
.fraction-slider .next{
	display:none;
	position:absolute;
	width:45px; height:45px;
	z-index:9999;
}

.fraction-slider .prev{
	left:10px; top:45%;
	background:url(images/fs.prevnext.png) 0px 0px no-repeat transparent;
	
}
.fraction-slider .prev:hover{
	background:url(images/fs.prevnext.png) 0px -45px no-repeat transparent;
}

.fraction-slider .next{
	right:10px; top:185px;
	background:url(images/fs.prevnext.png) -45px 0px no-repeat transparent;
}
.fraction-slider .next:hover{
	background:url(images/fs.prevnext.png) -45px -45px no-repeat transparent;
}

.fraction-slider:hover .prev,
.fraction-slider:hover .next{
	display:block;
}

/** PAGER **/

.fs-pager-wrapper{
	position:absolute;
	left:0px; bottom:-30px;
	z-index:9999;
	width:100%;
	text-align:center;
}

.fs-pager-wrapper a{
	display:inline-block;
	width:10px; height:10px;
	margin:0 10px 0 0;
	background: 0px -14px no-repeat #c6c6c6;
	border-radius:50%;
	outline:none;
}
.fs-pager-wrapper .active{
	background:0px 0px no-repeat #c0392b;
}